// to do
// 1. image need gatsby-image
// 2. optimizate LoadingImg function which loop all card
import React from 'react'
import { graphql } from 'gatsby'
import { SEO } from '@renderbus/common/components'

import {
  Header,
  HeaderTitle,
  HeaderBackground,
  HeaderNavBar,
  HeaderNavLink,
  CaseContainer,
  CaseCardList,
  CaseCard,
  CaseTitle,
  CaseInfo,
} from './vfx.atom'

import Layout from '../molecules/layout'
import cardInfoData from '../molecules/vfx/card-info-data'
import defaultImg from '../images/vfx/default-img.png'
import HeaderBgImg from '../images/vfx/header-bg-img.png'
import MBHeaderBgImg from '../images/vfx/header-bg-img-mobile.png'

class VFX extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      caseType: 4,
      waterFallList: [],
      waterFallColCount: 5,
      loadCount: 0,
      firstCardOfType: [1, 1, 1, 1],
      showCard: [],
      navBarIsFixed: false,
      navBarIsFixedOnPhone: false,
      navBarLeft: 5,
      isPhone: false,
    }
    this.seeHeight = 880
    this.startTime = new Date()
    this.caseContainerRef = null
    this.navBarRef = null
    this.switchCaseType = this.switchCaseType.bind(this)
    this.waterFallFlow = this.waterFallFlow.bind(this)
    this.resizeListener = this.resizeListener.bind(this)
    this.scrollListener = this.scrollListener.bind(this)
    this.LoadingImg = this.LoadingImg.bind(this)
    this.checkNav = this.checkNav.bind(this)
  }
  switchCaseType(caseType) {
    if (this.state.caseType === 4 || caseType === 4) {
      this.initWaterFall(caseType)
    }
    this.setState({
      caseType: caseType,
    })
    this.LoadingImg(caseType)
  }
  checkNav() {
    if (typeof window === 'undefined') {
      return
    }
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    //offsetTop is relative to offsetParent, not document
    let navOffTop = this.navBarRef.offsetTop
    let parent = this.navBarRef.offsetParent
    while (parent !== null) {
      navOffTop += parent.offsetTop
      parent = parent.offsetParent
    }
    if (scrollTop >= 10) {
      this.setState({
        navBarIsFixedOnPhone: true,
      })
    } else {
      this.setState({
        navBarIsFixedOnPhone: false,
      })
    }
    if (this.state.navBarIsFixed) {
      navOffTop += 230
    }
    if (scrollTop > navOffTop) {
      this.setState({
        navBarIsFixed: true,
      })
    } else {
      this.setState({
        navBarIsFixed: false,
      })
    }
  }
  LoadingImg(_caseType) {
    // copy of showCard
    if (typeof window === 'undefined') {
      return
    }
    let showCard = [...this.state.showCard]
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    let cards = document.getElementsByClassName('case-card')
    let firstCardOfType = this.state.firstCardOfType
    let card1 = null
    let card2 = null
    let card3 = null
    for (let i = cards.length - 1; i >= 0; i--) {
      let card = cards[i]
      let cardIndex = card.getAttribute('data-index')
      if (
        card.offsetTop > scrollTop - this.seeHeight &&
        card.offsetTop < scrollTop + this.seeHeight * 2
      ) {
        showCard[cardIndex] = true
      }
      if (firstCardOfType[2] === Number(cardIndex)) {
        card3 = card
      } else if (firstCardOfType[1] === Number(cardIndex)) {
        card2 = card
      } else if (firstCardOfType[0] === Number(cardIndex)) {
        card1 = card
      }
    }
    if ((_caseType || this.state.caseType) === 4) {
      this.setState({
        showCard: showCard,
      })
      return
    }
    let caseType = 1
    if (card3.offsetTop <= scrollTop + 1) {
      caseType = 3
    } else if (card2.offsetTop <= scrollTop + 1) {
      caseType = 2
    } else if (card1.offsetTop <= scrollTop + 1) {
      caseType = 1
    }
    this.setState({
      showCard: showCard,
      caseType: caseType,
    })
  }
  waterFallFlow(data, _caseType) {
    let { waterFallColCount } = this.state
    // copy of waterFallList firstCardOfType
    let waterFallList = [...this.state.waterFallList]
    let firstCardOfType = [...this.state.firstCardOfType]
    let caseContainerWidth = this.caseContainerRef.clientWidth
    // reduce padding 30px
    let caseCardListWidth = caseContainerWidth > 667 ? 260 : caseContainerWidth / 2 - 30
    let caseType = 0
    const _data = JSON.parse(JSON.stringify(data))
    if ((_caseType || this.state.caseType) === 4) {
      _data.caseQuery.edges = _data.caseQuery.edges.sort((node1, node2) => {
        const node1CreatedTime = node1.node.createdTime || node1.node.createdAt
        const node2CreatedTime = node2.node.createdTime || node2.node.createdAt
        return new Date(node2CreatedTime).getTime() - new Date(node1CreatedTime).getTime()
      })
      firstCardOfType[4] = 0
    }
    _data.caseQuery.edges.forEach((node, index) => {
      if (!node || !node.node || !node.node.image) return
      let imagePath = ''
      if (!node.node.image.localFile || !node.node.image.localFile.publicURL) {
        imagePath = node.node.image.file.url
      } else {
        imagePath = node.node.image.localFile.publicURL
      }
      let height = node.node.image.file.details.image.height
      let width = node.node.image.file.details.image.width
      let realHeight = caseCardListWidth * (height / width) // the img height after compute
      let cardInfo = new cardInfoData(
        node.node.id,
        node.node.title,
        node.node.intro.intro,
        imagePath,
        node.node.sort,
        height,
        width,
        realHeight,
        index,
        node.node.caseType,
      )
      // the first card of type
      if (cardInfo.caseType !== caseType) {
        firstCardOfType[caseType] = index
        caseType = cardInfo.caseType
      }
      let image = new Image()
      image.src = imagePath
      if (waterFallList.length > 0) {
        waterFallList[index % waterFallColCount].push(cardInfo)
      }
    })
    //begin loading 12 image
    let showCard = Array.from(new Array(12), (x) => true)
    this.setState({
      waterFallList: waterFallList,
      firstCardOfType: firstCardOfType,
      showCard: showCard,
    })
  }
  //according to caseContainer's width init waterFall
  initWaterFall(caseType) {
    let caseContainer = this.caseContainerRef
    let waterFallColCount = 5
    //300 is CaseCard's width
    if (caseContainer.clientWidth < 667) {
      waterFallColCount = 2
    } else {
      waterFallColCount = parseInt(caseContainer.clientWidth / 260)
    }
    //creat array include waterFallColCount of []
    let waterFallList = Array.from(new Array(waterFallColCount), (x) => [])
    this.isLoading = false

    //set navbar left

    let documentWidth = document.documentElement.offsetWidth
    let caseConatinerWidth = caseContainer.offsetWidth
    let navBarWidth = 124
    this.setState(
      {
        navBarLeft: (documentWidth - caseConatinerWidth) / 2 - navBarWidth,
        waterFallColCount: waterFallColCount,
        waterFallList: waterFallList,
        loadCount: 0,
        isPhone: this.caseContainerRef.clientWidth < 667,
      },
      () => {
        this.waterFallFlow(this.props.data, caseType)
      },
    )
  }
  resizeListener() {
    //debounce
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer)
    }
    this.resizeTimer = setTimeout(() => {
      this.initWaterFall()
      if (typeof window === 'undefined') {
        return
      }
      document.documentElement.scrollTop = 0
    }, 200)
  }
  scrollListener() {
    //Throttling
    let curTime = new Date()
    let mustRun = 500
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer)
    }
    if (curTime - this.startTime >= mustRun) {
      this.startTime = curTime
      this.LoadingImg()
    } else {
      this.scrollTimer = setTimeout(() => {
        this.startTime = curTime
        this.LoadingImg()
      }, 500)
    }
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    this.seeHeight = document.documentElement.clientHeight
    this.initWaterFall(4)

    window.addEventListener('resize', this.resizeListener)
    window.addEventListener('scroll', this.scrollListener)
    window.addEventListener('scroll', this.checkNav)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener)
    window.removeEventListener('scroll', this.scrollListener)
    window.removeEventListener('scroll', this.checkNav)
  }

  setCaseContainerRef = (ref) => {
    this.caseContainerRef = ref
  }
  setNavBarRef = (ref) => {
    this.navBarRef = ref
  }

  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    const {
      waterFallList,
      showCard,
      firstCardOfType,
      navBarIsFixed,
      navBarIsFixedOnPhone,
      caseType,
      navBarLeft,
      isPhone,
    } = this.state
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='Renderbus云渲染案例,影视|动画|效果图渲染案例_Renderbus|瑞云渲染农场'
          keywords='瑞云渲染案例'
          description='瑞云渲染案例,包括哪吒之魔童降世,流浪地球,战狼2,影,捉妖记2,唐人街探案2,三个机器人,熊出没,大圣归来,哈布洛先生,效果图案例等.'
          sharePostSlug='vfx.html'
        />
        <Header navBarIsFixed={navBarIsFixedOnPhone}>
          <HeaderTitle>影视视效·三维动画·设计效果图 · 一站渲染</HeaderTitle>
          <HeaderBackground src={isPhone ? MBHeaderBgImg : HeaderBgImg}></HeaderBackground>
          <HeaderNavBar
            ref={this.setNavBarRef}
            isFixed={navBarIsFixed}
            isFixedOnPhone={navBarIsFixedOnPhone}
            left={navBarLeft}
          >
            <HeaderNavLink
              href={`#card-${firstCardOfType[4]}`}
              onClick={() => {
                this.switchCaseType(4)
              }}
              isActive={caseType === 4}
            >
              全部
            </HeaderNavLink>
            <HeaderNavLink
              href={`#card-${firstCardOfType[0]}`}
              onClick={() => {
                this.switchCaseType(1)
              }}
              isActive={caseType === 1}
            >
              影视视效
            </HeaderNavLink>
            <HeaderNavLink
              href={`#card-${firstCardOfType[1]}`}
              onClick={() => {
                this.switchCaseType(2)
              }}
              isActive={caseType === 2}
            >
              三维动画
            </HeaderNavLink>
            <HeaderNavLink
              href={`#card-${firstCardOfType[2]}`}
              onClick={() => {
                this.switchCaseType(3)
              }}
              isActive={caseType === 3}
            >
              效果图
            </HeaderNavLink>
          </HeaderNavBar>
        </Header>
        <CaseContainer ref={this.setCaseContainerRef}>
          {waterFallList.map((cardList, index) => {
            return (
              <CaseCardList key={index}>
                {cardList.map((card) => {
                  return (
                    <CaseCard
                      key={card.index}
                      id={`card-${card.index}`}
                      className='case-card'
                      data-index={card.index}
                    >
                      <img
                        src={showCard[card.index] ? card.imagePath : defaultImg}
                        alt={card.title}
                        style={{ height: card.realHeight }}
                      />
                      <CaseTitle>{card.title}</CaseTitle>
                      {card.intro !== 'NULL' && <CaseInfo>{card.intro}</CaseInfo>}
                    </CaseCard>
                  )
                })}
              </CaseCardList>
            )
          })}
        </CaseContainer>
      </Layout>
    )
  }
}
export default VFX

export const query = graphql`
  query {
    caseQuery: allContentfulCase(sort: { fields: [caseType, sort], order: [ASC, DESC] }) {
      edges {
        node {
          id
          title
          sort
          caseType
          createdAt
          createdTime
          intro {
            intro
          }
          image {
            contentful_id
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
