class cardInoData {
  constructor(
    id = '',
    title = '',
    intro = '',
    imagePath = '',
    sort = '',
    height = 0,
    width = 0,
    realHeight = 0,
    index = 0,
    caseType = '',
  ) {
    this.id = id
    this.title = title
    this.intro = intro
    this.imagePath = imagePath
    this.sort = sort
    this.height = height
    this.width = width
    this.realHeight = realHeight
    this.index = index
    this.caseType = caseType
  }
}
export default cardInoData
