import styled from 'styled-components'
import { typography, color, transition } from '@renderbus/common/theme'

import { H1 } from '@renderbus/common/components'

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto 55px;
  height: 380px;
  max-width: 1300px;
  z-index: 1;
  @media (max-width: 658px) {
    margin: 0 0 5.33vw;
    width: 100%;
    height: 56.13vw;
  }
`
export const HeaderTitle = styled(H1)`
  margin: 0.67em 0;
  @media (max-width: 1140px) {
    margin: 50px 0 0;
    font-size: 4.8vw;
  }
`
export const HeaderBackground = styled.img`
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
`
export const HeaderNavBar = styled.div`
  position: ${p => (p.isFixed ? 'fixed' : 'absolute')};
  bottom: ${p => p.isFixed || '0px'};
  top: ${p => p.isFixed && '70px'};
  left: ${p => (p.isFixed ? `${p.left}px` : '5px')};
  width: ${p => (p.isFixed ? 'auto' : '400px')};
  display: flex;
  justify-content: space-between;
  flex-direction: ${p => (p.isFixed ? 'column' : 'row')};
  text-align: center;
  @media (max-width: 1140px) {
    position: ${p => (p.isFixedOnPhone ? 'fixed' : 'absolute')};
    top: ${p => p.isFixedOnPhone && '60px'};
    left: 0;
    bottom: ${p => p.isFixedOnPhone || '0'};
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    font-size: 3.2vw;
    background: ${p => p.isFixedOnPhone && 'rgba(51,51,51,0.9)'};
  }
`

export const HeaderNavLink = styled.a`
  padding: 7px 3px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  background-color: ${p => p.isActive && color.panel};
  border-radius: 6px;
  text-align: center;
  width: 100px;
  color: ${p => p.isActive && color.primary};
  @media (max-width: 658px) {
    width: 17.33vw;
    height: 7.33vw;
    padding: 0;
    line-height: 7.33vw;
  }
`
export const CaseContainer = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: 658px) {
    padding: 0 2.46vw;
  }
`
export const CaseCardList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px 20px;
  color: white;
  width: 260px;
  @media (max-width: 658px) {
    width: 50%;
    padding: 0 1.53vw 3.73vw;
  }
`
export const CaseTitle = styled.p`
  font-size: ${typography.text};
  margin: 20px 0 10px 0;
`
export const CaseInfo = styled.p`
  font-size: ${typography.textThin};
  margin: 0;
  color: ${color.prompt};
`
export const CaseCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px;
  margin-bottom: ${typography.text};
  overflow: hidden;
  color: white;
  background-color: ${color.panel};
  transition: ${transition('background-color')};
  img {
    width: 100%;
    transition: ${transition('transform')};
  }
  :hover {
    background-color: ${color.primary};
    img {
      transform: scale(1.1);
    }
    ${CaseInfo} {
      color: ${color.white};
    }
  }
`
